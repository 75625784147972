/* Staking.css */
.staking-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the boxes horizontally */
    align-items: center; /* Center the boxes vertically */
    margin-top: 128px;
    background-color: var(--color-8);
    border-radius: 16px;
  }

  .staking-levels {
    display: flex;
    flex-direction: column; /* Display levels in a column */
    align-items: center;
    margin: 16px; /* Add margin on all sides to separate the boxes */
  }
  
  .staking-level {
    padding: 16px;
    background-color: var(--color-9);
    border-radius: 16px;
    width: 300px;
    margin: 6px; /* Add margin between levels */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically within the box */
    align-items: center; /* Center the content horizontally within the box */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .selected {
    background-color: var(--color-7); /* Change background color for the selected level */
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.4), 0 6px 20px 0 rgba(255, 255, 255, 0.3); /* Updated box-shadow */
  }
  
  
  .level-info {
    text-align: center;
  }
  
  .level-name {
    color: var(--color-6);
    font-weight: 600;
    font-size: 18px;
  }
  
  .level-discount {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
  }
  
  .level-fee {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
  }
  
  .staking-balance {
    padding: 16px;
    background-color: var(--color-9);
    border-radius: 16px;
    width: 324px;
    color: var(--color-5);
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between elements */
  }

  .stake-input {
    padding: 16px;
    border-radius: 16px;
    display: flex;
    margin: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .stake-button {
    border-radius: 12px;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    gap: 20px;
    flex-direction: row;
    padding: 8px 32px;
    text-decoration: none;
    background-color: var(--color-7);
  }
  
  .stake-button:hover {
    background-color: var(--color-6);
    border-radius: 12px;
    color: #ffffff;
    
  }
  
  .staking-discount {
    padding: 16px;
    background-color: var(--color-8);
    border-radius: 16px;
    color: var(--color-5);
    width: 300px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .staking-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 16px; /* Add some spacing below the tabs */
  }
  
  .tab-button {
    background-color: var(--color-7); /* Default background color */
    color: var(--color-6); /* Default text color */
    border: none;
    border-radius: 12px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-button.active {
    background-color: var(--color-6); /* Background color when selected */
    color: var(--color-8); /* Text color when selected */
  }
  
  .input-container {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow the input to grow and take available space */
  }
  
  .stake-input {
    flex-grow: 1; /* Input takes available space */
    padding: 6px; /* Add padding for better appearance */
  }
  
  .max-button {
    background-color: var(--color-7); /* Customize the button styles */
    color: #fff;
    padding: 6px 12px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
  }

  .max-button:hover {
    background-color: var(--color-6); /* Customize the button styles */
  }