html,
:root {
    --soft-blue: #6D3B47;
    --texthover: var(--color-3);

    --main-bg: var(--color-9);
    --card-bg: #282F44;
    --dark-blue: var(--color-9);

    --white: hsl(0, 0%, 100%);
    --color-0: #E3E8ED;  /* Light Gray */
    --color-1: #CFD5DC;  /* Slightly Desaturated Blue Gray */
    --color-2: #BAC2CA;  /* Blue Gray */
    --color-3: #A5AEC0;  /* Deeper Blue Gray */
    --color-4: #8996A8;  /* Subtle Blue */
    --color-5: #6C7B94;  /* Fading Purple */
    --color-6: #5A6378;  /* Purple-Gray */
    --color-7: #404953;  /* Darkest Gray-Blue */
    --color-8: #343A40;
    --color-9: #212529;

}

body {
    text-align: center;
    background-size: cover;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, "JetBrains Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.child {
    width: 33%;
    height: 33%;
    /* Center horizontally*/
    margin: 0 auto;
}

.button {
    background-color: var(--color-7);  /* Subtle Blue as primary button color */
    border: none;
    border-radius: 12px;
    color: var(--color-1);            /* Lightest Gray for text */
    cursor: pointer;
    font-size: 16px;
    padding: 12px 24px;
    transition: transform 0.3s, background-color 0.3s;
    font-weight: 600;
    text-shadow: 1px 1px 1px var(--color-8);  /* Using Purple-Gray for a subtle text shadow */
}

.button:hover {
    background-color: var(--color-6);  /* Fading Purple for hover effect */
    transform: translateY(-2px);       /* Slight lift on hover */
}

.button:active {
    background-color: var(--color-8);  /* Purple-Gray for active/click effect */
    transform: translateY(0);
}

.dapp-container {
    display: flex;                 /* Enables flexbox */
    justify-content: space-between;/* Spaces out the direct children across the main axis */
    align-items: center;           /* Centers direct children on the cross axis */
    flex-wrap: wrap;               /* Allows children to wrap to the next line if there's not enough space */
    gap: 1rem;                     /* Spacing between children. Useful if flex items wrap to the next line. */
    padding: 1rem;                 /* Padding inside the container */
    box-sizing: border-box;        /* Ensures padding and border are included in total width/height */
}

.home-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

.logo-section {
  margin-bottom: 40px;
  align-items: center;
}

.launch-button {
  margin: 20px 0;
}

.resources-section h2 {
  margin-bottom: 30px;
  font-size: 2em;
  color: var(--color-0);
}

.resource-group {
    margin-bottom: 80px;
    border: 1px solid #ccc;  /* Adjust the color as needed */
    padding: 20px;           /* Adjust padding as needed */
    margin: 20px 0;          /* Adjust margin as needed */
    border-radius: 12px;      /* Optional: to give rounded corners */
  }


  

.resource-group h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: var(--color-0);
}

.resources-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.resource {
    margin: 8px;
}

.resource a {
  text-decoration: none;
}

.resource a:hover {
  text-decoration: underline;
}
