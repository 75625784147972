@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");



body {
  width: 100vw;
  height: 100vh;
  font-size: 18px;

  background-color: var(--main-bg);

  display: flex;
  flex-direction: column;
  align-items: center;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.buy-button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove button border */
  border-radius: 16px; /* Rounded corners */
  text-decoration: none; /* Remove underlining for links */
  cursor: pointer; /* Add pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.buy-button:hover {
  background-color: #0056b3; /* Button background color on hover */
}

.card {
  padding: 16px;
  background-color: var(--color-8);

  border-radius: 16px;

  width: 300px;
  margin-top: 16px;
  margin-left: 16px;

  display: inline-flex;
  flex-direction: column;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card a, .card a:hover, .card a:visited {
  color: inherit;
  text-decoration: none;
}

.card a:hover {
  text-decoration: underline; /* Optional: underline on hover for better UX */
}


.card-big {
  padding: 16px;
  background-color: var(--color-8);
  border-radius: 16px;
  max-width: 1024px;
  margin-top: 64px;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap; /* allows items to wrap to the next line if there isn’t enough space */
  gap: 16px; /* spacing between the sections */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-big > div {
  min-width: 200px; /* You can adjust this as needed */
  flex: 1; /* Makes sure each box takes up equal space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) { /* Adjusts for smaller screens (like tablets). */
  .card-big {
      flex-direction: column;
  }

  .card-big > div {
      min-width: 100%; /* Ensures that the boxes stretch across the whole width when stacked */
  }
}


.card-big--image-container {
  flex: 1; /* This will allow the image section to take up 1/3 of the space */
  display: flex;
  align-items: center;
  justify-content: center;

}

.card-big--attributes {
  flex: 1; /* This will allow the attributes section to take up 1/3 of the space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: var(--color-7);
  padding: 12px;
}

.attributes-container {
  display: flex;
  flex-direction: column;
  gap: 16px; 
}

.primary-attributes, .secondary-attributes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); 
  gap: 8px; 
}

.certification {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #ddd; /* Provides a separator line */
  padding-top: 8px;
}

.attribute-item {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 16px;
}

.attribute-name {
  color: var(--color-5);
  font-weight: 400;
  font-size: 18px;
}

.attribute-value {
  color: var(--color-4); 
}


.card-big--links {
  flex: 1; /* This will allow the links section to take up 1/3 of the space */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.card--image, .card-big--image {
  width: 100%;
  border-radius: 16px;
  background-color: transparent;
}

.card-big--image {
  width: 100%;

  border-radius: 16px;
  background-color: transparent;
}

.card--image-ctn {
  position: relative;
}

.card--image-ctn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.card--image-ctn:hover svg {
  visibility: visible;
}

.card--image:hover {
  filter: grayscale(100%) sepia(80%) hue-rotate(248deg) brightness(80%)
    contrast(107%);
}

h1 {
 padding: 0;
 margin: 2px 0;
 font-weight: 600;
 font-size: 18px;
 color: var(--white);
 text-decoration: none;
}

.title {
  color: var(--color-4);
  font-weight: 600;
  font-size: 18px;
}

.subtitle {
  color: var(--white);
  font-weight: 300;
  font-size: 16px;
}

.metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
}

.attributes {
  display: flex;
  align-items: center;
}

.attribute {
  padding: 16px;
  background-color: var(--color-7);

  border-radius: 16px;

  margin-top: 16px;
  margin-left: 16px;

  display: inline-flex;
  flex-direction: column;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.attribute:hover {
  background-color: var(--color-6);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--dark-blue);
  margin: 10px 0;
  padding: 0;
}

.attribution {
  display: flex;
  align-items: center;
  margin-top: 40px;
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--texthover);
}

