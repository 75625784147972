@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap);
html,
:root {
    --soft-blue: #6D3B47;
    --texthover: var(--color-3);

    --main-bg: var(--color-9);
    --card-bg: #282F44;
    --dark-blue: var(--color-9);

    --white: hsl(0, 0%, 100%);
    --color-0: #E3E8ED;  /* Light Gray */
    --color-1: #CFD5DC;  /* Slightly Desaturated Blue Gray */
    --color-2: #BAC2CA;  /* Blue Gray */
    --color-3: #A5AEC0;  /* Deeper Blue Gray */
    --color-4: #8996A8;  /* Subtle Blue */
    --color-5: #6C7B94;  /* Fading Purple */
    --color-6: #5A6378;  /* Purple-Gray */
    --color-7: #404953;  /* Darkest Gray-Blue */
    --color-8: #343A40;
    --color-9: #212529;

}

body {
    text-align: center;
    background-size: cover;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, "JetBrains Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.child {
    width: 33%;
    height: 33%;
    /* Center horizontally*/
    margin: 0 auto;
}

.button {
    background-color: var(--color-7);  /* Subtle Blue as primary button color */
    border: none;
    border-radius: 12px;
    color: var(--color-1);            /* Lightest Gray for text */
    cursor: pointer;
    font-size: 16px;
    padding: 12px 24px;
    transition: transform 0.3s, background-color 0.3s;
    font-weight: 600;
    text-shadow: 1px 1px 1px var(--color-8);  /* Using Purple-Gray for a subtle text shadow */
}

.button:hover {
    background-color: var(--color-6);  /* Fading Purple for hover effect */
    transform: translateY(-2px);       /* Slight lift on hover */
}

.button:active {
    background-color: var(--color-8);  /* Purple-Gray for active/click effect */
    transform: translateY(0);
}

.dapp-container {
    display: flex;                 /* Enables flexbox */
    justify-content: space-between;/* Spaces out the direct children across the main axis */
    align-items: center;           /* Centers direct children on the cross axis */
    flex-wrap: wrap;               /* Allows children to wrap to the next line if there's not enough space */
    grid-gap: 1rem;
    gap: 1rem;                     /* Spacing between children. Useful if flex items wrap to the next line. */
    padding: 1rem;                 /* Padding inside the container */
    box-sizing: border-box;        /* Ensures padding and border are included in total width/height */
}

.home-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

.logo-section {
  margin-bottom: 40px;
  align-items: center;
}

.launch-button {
  margin: 20px 0;
}

.resources-section h2 {
  margin-bottom: 30px;
  font-size: 2em;
  color: var(--color-0);
}

.resource-group {
    margin-bottom: 80px;
    border: 1px solid #ccc;  /* Adjust the color as needed */
    padding: 20px;           /* Adjust padding as needed */
    margin: 20px 0;          /* Adjust margin as needed */
    border-radius: 12px;      /* Optional: to give rounded corners */
  }


  

.resource-group h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: var(--color-0);
}

.resources-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.resource {
    margin: 8px;
}

.resource a {
  text-decoration: none;
}

.resource a:hover {
  text-decoration: underline;
}

.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .data {
    height: 675px;
  }
  .home {
    padding: 10px;
    /* margin:10px; */
    list-style: none;

  
  }
  .home :hover {
    background-color: var(--color-9);
    border-radius: 6px;
  }
  .crm {
    /* padding: 10px; */
    /* margin:10px; */
      list-style: none;

  }
  .icon {
    margin-left: 200px;
    color: yellow;
  }

  /* Container holding your dropdown, this should be relative */
.dropdown-container {
  position: relative;
  /* other styles as needed */
}

/* Styles for the dropdown list */
.listbox-options {
  position: absolute;
  top: 100%; /* Positioning the dropdown right below the container */
  z-index: 1000; /* High z-index to ensure it is above other elements */
  max-height: 333px;
  overflow-y: auto;
  background-color: var(--white);
  color: black;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 12px; /* Adjust padding as needed */
  width: -webkit-max-content;
  width: max-content; /* Makes the width fit the longest text item */
  min-width: 160px; /* Optional: Set a minimum width to ensure usability */
}

/* Adjusting the individual option styles */
.custom-option {
  cursor: default;
  position: relative;
  padding: 8px 10px; /* Increased padding for better spacing */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
}


.custom-active {
  color: var(--color-1);
  background-color: var(--color-7);
  border-radius: 12px;
  padding: 8px 10px; /* Increased padding for better spacing */

}

.custom-inactive {
  color: var(--color-1);
  background-color: transparent;
  border-radius: 12px;
}
/* CSS for the active listbox option */
.listbox-option-active {
  background-color: white; /* Set background color to white for active option */
  color: black; /* Set text color that ensures good readability on a white background */
  /* Add any other styling properties you need, like padding, font-weight, etc. */
}

.sort-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body {
  width: 100vw;
  height: 100vh;
  font-size: 18px;

  background-color: var(--main-bg);

  display: flex;
  flex-direction: column;
  align-items: center;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.buy-button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove button border */
  border-radius: 16px; /* Rounded corners */
  text-decoration: none; /* Remove underlining for links */
  cursor: pointer; /* Add pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.buy-button:hover {
  background-color: #0056b3; /* Button background color on hover */
}

.card {
  padding: 16px;
  background-color: var(--color-8);

  border-radius: 16px;

  width: 300px;
  margin-top: 16px;
  margin-left: 16px;

  display: inline-flex;
  flex-direction: column;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card a, .card a:hover, .card a:visited {
  color: inherit;
  text-decoration: none;
}

.card a:hover {
  text-decoration: underline; /* Optional: underline on hover for better UX */
}


.card-big {
  padding: 16px;
  background-color: var(--color-8);
  border-radius: 16px;
  max-width: 1024px;
  margin-top: 64px;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap; /* allows items to wrap to the next line if there isn’t enough space */
  grid-gap: 16px;
  gap: 16px; /* spacing between the sections */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-big > div {
  min-width: 200px; /* You can adjust this as needed */
  flex: 1 1; /* Makes sure each box takes up equal space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) { /* Adjusts for smaller screens (like tablets). */
  .card-big {
      flex-direction: column;
  }

  .card-big > div {
      min-width: 100%; /* Ensures that the boxes stretch across the whole width when stacked */
  }
}


.card-big--image-container {
  flex: 1 1; /* This will allow the image section to take up 1/3 of the space */
  display: flex;
  align-items: center;
  justify-content: center;

}

.card-big--attributes {
  flex: 1 1; /* This will allow the attributes section to take up 1/3 of the space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: var(--color-7);
  padding: 12px;
}

.attributes-container {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px; 
}

.primary-attributes, .secondary-attributes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); 
  grid-gap: 8px; 
  gap: 8px; 
}

.certification {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  border-top: 1px solid #ddd; /* Provides a separator line */
  padding-top: 8px;
}

.attribute-item {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 16px;
}

.attribute-name {
  color: var(--color-5);
  font-weight: 400;
  font-size: 18px;
}

.attribute-value {
  color: var(--color-4); 
}


.card-big--links {
  flex: 1 1; /* This will allow the links section to take up 1/3 of the space */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.card--image, .card-big--image {
  width: 100%;
  border-radius: 16px;
  background-color: transparent;
}

.card-big--image {
  width: 100%;

  border-radius: 16px;
  background-color: transparent;
}

.card--image-ctn {
  position: relative;
}

.card--image-ctn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.card--image-ctn:hover svg {
  visibility: visible;
}

.card--image:hover {
  filter: grayscale(100%) sepia(80%) hue-rotate(248deg) brightness(80%)
    contrast(107%);
}

h1 {
 padding: 0;
 margin: 2px 0;
 font-weight: 600;
 font-size: 18px;
 color: var(--white);
 text-decoration: none;
}

.title {
  color: var(--color-4);
  font-weight: 600;
  font-size: 18px;
}

.subtitle {
  color: var(--white);
  font-weight: 300;
  font-size: 16px;
}

.metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
}

.attributes {
  display: flex;
  align-items: center;
}

.attribute {
  padding: 16px;
  background-color: var(--color-7);

  border-radius: 16px;

  margin-top: 16px;
  margin-left: 16px;

  display: inline-flex;
  flex-direction: column;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.attribute:hover {
  background-color: var(--color-6);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--dark-blue);
  margin: 10px 0;
  padding: 0;
}

.attribution {
  display: flex;
  align-items: center;
  margin-top: 40px;
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--texthover);
}


body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.container {
    display: flex;
    padding-top: 50px; /* Padding equal to the height of the tabs, so content isn't overlapped */
}


/* styles.css */

.topbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: wrap;
    padding: 10px 20px;
    background-color: var(--color-9);
    color: white;
    z-index: 1001; /* to ensure it stays on top */
    margin-left: 300px; /* Adjust based on your sidebar's width */
}

.search-container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;  /* Adjust the gap to your liking */
}

.topbar-item {
    margin-right: 15px; /* consistent spacing instead of 'gap' */
}

.buttons-container {
    display: flex;
    grid-gap: 10px;
    gap: 10px; /* space between the buttons */
    align-items: center;
    border-radius: 8px;
    background-color: var(--color-7);
}

.topbar-search {
    flex-grow: 1; /* allows this element to take up the remaining space */
    margin: 0 15px;
}

.topbar-tabs {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

/* Responsive Design */
@media (max-width: 979px) {
    .topbar-container {
        flex-direction: column;
        margin-left: 64px; /* Adjust based on your sidebar's responsive width */
    }

    .topbar-search {
        width: 100%; /* full width in a vertical layout */
        margin-bottom: 15px; /* space between items in a column */
    }
}

.explorer-container {
    margin-left: 210px; /* space for the sidebar */
    margin-top: 64px; /* space for the topbar, adjust as needed */
    z-index: 1000; /* to ensure it stays on top */

}



/* Style for the filter sections */
.filter-section {
    border-bottom: 1px solid #ddd; /* Horizontal line */
}


/* Explorer.css */
.footer-content {
    padding: 20px;
    margin-bottom: 80px;
    color: white;
}

.market-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; /* Adjust based on your preference */
    margin-top: 20px; /* Optional: for extra space between text and logos */
    padding-bottom: 20px;
}

.market-logo {
    width: 32px; /* Or the appropriate size for your design */
    height: auto; /* Maintain aspect ratio */
}



.trait-toggle {
    cursor: pointer;
}

.trait-detail {
    margin-left: 20px;
}

.list-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
}

.list-item {
    display: flex;
    grid-template-columns: 
        minmax(100px, 1fr)  /* Image cell */
        2fr  /* Name cell */
        1fr 1fr 1fr 1fr 1fr 2fr;  /* Other attribute cells */
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    background-color: #f8f8f8;
}

/* Remove the right border for the last cell */
.list-item .list-cell:last-child {
    border-right: none;
}

.diamond-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.list-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-right: 1px solid #e0e0e0; /* This creates the separator */
    flex: 1 1; /* This ensures equal width for all cells */
}


.diamond-info {
    flex: 1 1;
    display: grid;
    grid-gap: 5px;
    gap: 5px;  /* Space between each line of info */
}

.diamond-info h5 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}



.tab {
    display: flex;
    align-items: center;  /* vertically aligns items in the center */
    justify-content: center;  /* horizontally aligns items in the center */
    grid-gap: 5px;
    gap: 5px;  /* spacing between SVG and text */
    padding: 10px 20px;  /* adjust padding as needed */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.tab:hover {
    background-color: #ddd;  /* adjust hover color as needed */
}

.tab-icon {
    width: 24px;  /* adjust SVG size as needed */
    height: 24px;
}


.tab.active {
    background-color: var(--color-4);
    color: var(--color-9);
}

.tab svg {
    width: 24px;
    height: 24px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust 250px to your preferred width */
    grid-gap: 20px;
    gap: 20px; /* Space between grid items */
    padding: 20px;
}

.grid-item {
    display: grid;
    grid-gap: 5px;
    gap: 5px; /* Space between lines inside an item */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f8f8;
}

/* Additional styles for the item attributes for aesthetics */
.item-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

/* Adjust other styles for 'item-price', 'item-offer', etc. as needed */

.horizontal-slider {
    width: 100%;
    height: 25px;
}

.thumb {
    height: 25px;
    width: 25px;
    background-color: var(--color-6);
    border-radius: 16px;
    cursor: pointer;
}

.track {
    top: 10px;
    bottom: 10px;
    background: #ddd;
}

/* Redeem.css */

.redeem-container {
    padding: 16px;
    background-color: var(--color-8);
    border-radius: 16px;
    max-width: 512px;
    margin: 64px auto; /* Center the card horizontally and add margin on top */
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .centered-content {
    text-align: center;
  }
  
  .section-title {
    color: var(--color-5);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .section-description {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .balance-info {
    color: var(--white);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .balance-info p {
    color: var(--color-7);
    font-weight: 400;
    font-size: 16px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure this is higher than other elements */
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: -1; /* Place behind modal content */
}

.modal-content {
    background: var(--color-9);
    color: var(--color-1);
    padding: 20px;
    border-radius: 10px;
    width: 80%; /* Adjust as needed */
    max-width: 500px; /* Adjust as needed */
    z-index: 1; /* Ensure this is higher than the overlay */
}

.delivery-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.delivery-option, .pickup-option {
  margin: 10px 10px;
}

.delivery-tabs {
  display: flex;
  flex-direction: row;
}


.delivery-content, .pickup-content {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid var(--color-1);
  border-radius: 8px;
  background-color: var(--color-9);
  margin: 8px;
}

p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.5;
}

/* Staking.css */
.staking-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the boxes horizontally */
    align-items: center; /* Center the boxes vertically */
    margin-top: 128px;
    background-color: var(--color-8);
    border-radius: 16px;
  }

  .staking-levels {
    display: flex;
    flex-direction: column; /* Display levels in a column */
    align-items: center;
    margin: 16px; /* Add margin on all sides to separate the boxes */
  }
  
  .staking-level {
    padding: 16px;
    background-color: var(--color-9);
    border-radius: 16px;
    width: 300px;
    margin: 6px; /* Add margin between levels */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically within the box */
    align-items: center; /* Center the content horizontally within the box */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .selected {
    background-color: var(--color-7); /* Change background color for the selected level */
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.4), 0 6px 20px 0 rgba(255, 255, 255, 0.3); /* Updated box-shadow */
  }
  
  
  .level-info {
    text-align: center;
  }
  
  .level-name {
    color: var(--color-6);
    font-weight: 600;
    font-size: 18px;
  }
  
  .level-discount {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
  }
  
  .level-fee {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
  }
  
  .staking-balance {
    padding: 16px;
    background-color: var(--color-9);
    border-radius: 16px;
    width: 324px;
    color: var(--color-5);
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px; /* Adjust the gap between elements */
  }

  .stake-input {
    padding: 16px;
    border-radius: 16px;
    display: flex;
    margin: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .stake-button {
    border-radius: 12px;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: row;
    padding: 8px 32px;
    text-decoration: none;
    background-color: var(--color-7);
  }
  
  .stake-button:hover {
    background-color: var(--color-6);
    border-radius: 12px;
    color: #ffffff;
    
  }
  
  .staking-discount {
    padding: 16px;
    background-color: var(--color-8);
    border-radius: 16px;
    color: var(--color-5);
    width: 300px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .staking-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 16px; /* Add some spacing below the tabs */
  }
  
  .tab-button {
    background-color: var(--color-7); /* Default background color */
    color: var(--color-6); /* Default text color */
    border: none;
    border-radius: 12px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab-button.active {
    background-color: var(--color-6); /* Background color when selected */
    color: var(--color-8); /* Text color when selected */
  }
  
  .input-container {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow the input to grow and take available space */
  }
  
  .stake-input {
    flex-grow: 1; /* Input takes available space */
    padding: 6px; /* Add padding for better appearance */
  }
  
  .max-button {
    background-color: var(--color-7); /* Customize the button styles */
    color: #fff;
    padding: 6px 12px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
  }

  .max-button:hover {
    background-color: var(--color-6); /* Customize the button styles */
  }


header {
  display: flex;
  position: fixed;
  margin-left: 0;
  background: transparent;
  height: 64px;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1;
}

.Menu {
  position: fixed;
  top: 0px; /* Adjust the top position as needed */
  left: 0px; /* Adjust the left position as needed */
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: var(--color-7);
  align-items: flex-start;
  max-height: 90vh; /* prevent items from going off the screen */
  overflow-y: auto; /* enable scrolling if items exceed max-height */
  margin-top: auto;
  border-radius: 16px;
}

.input {
  padding: 16px;
  border-radius: 16px;
  display: flex;
  margin: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.menuItem {
  border-radius: 13px;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  padding: 12px 24px;
  text-decoration: none;
  background-color: var(--color-8);
}

.menuItem:hover {
  background-color: var(--color-6);
  border-radius: 16px;
  color: #ffffff;
  
}


@media screen and (min-width: 600px) {
  .Menu {
    flex-direction: row;
    align-items: start;
    max-height: 90vh;
    overflow-y: auto;
    margin-top: auto;
    padding: 10px; /* Adjust padding as needed */
  }

  .Menu .menuItem {
    margin-top: 0;
    margin-right: 15px;
    padding: 8px 32px;
    border-radius: 16px;
    color: #fff;
    background-color: var(--color-8); /* Set the background color to match UserInfo */
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }

  .Menu .menuItem:hover {
    background-color: var(--color-7);
  }



  .hamburger {
    position: relative;
    top: auto;
    left: auto;
  }


}

.wallet-status a {
  color: #ea4c4c;
  margin-left: 8px;
  font-weight: 600;
  text-decoration: underline;
}

.status {
  background: #ec3f3f;
  border-radius: 50%;
  height: 10px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  width: 10px;
  display: table-cell;
}

.status-connected {
  background: #00ff00;
  border-radius: 50%;
  height: 10px;
  margin-right: 8px;
  margin-left: 8px;
  position: relative;
  width: 10px;
}


.network {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 6px 16px;
  background-color: var(--color-8);
  border-radius: 16px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #fff;
}

.network img {
  width: 20px;
  height: 20px;
  padding: 14px;
}

.network svg {
  width: 20px;
  height: 20px;
  margin-right: 14px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -2px;
}

.network:before {
  content: '';
  display: block;
  width: 100%;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.transitionFade {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 10px; /* Adjust padding as needed */
}

.transitionFade.transitionFadeOpen {
  opacity: 1;
}

.transitionFade.transitionFadeClosed {
  opacity: 0;
}


.network.connected {
  justify-content: flex-start;
}

.network.connected:before {
  display: none;
}

.network .item {
  background: #616161;
  border-radius: 16px;
  color: #fff;
}

.network .item:hover {
  background: var(--dark-blue);
  color: #fff;
}


.networkSelect {
  position: absolute;
  right: 0;
  background-color: var(--color-8);
  border-radius: 16px;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 16px;
  cursor: pointer;
  z-index: 2; /* Ensure this is above other modal content */
  color: #fff;
}

.networkSelect .item {
  background: var(--color-8);
  border-radius: 16px;
  padding: 8px;
  margin-bottom: 4px;
}

.networkSelect .item:last-child {
  margin-bottom: 0;
}

.networkSelect .item:hover {
  background: var(--color-7);
}

/* userInfo.css */
.menuBar {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 6px 16px;
  background-color: var(--color-7);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #fff;
}

#userInfo {
  position: relative; /* Ensure the user info container is relative */
  z-index: 1111;
}

.modalOverlay {
  position: absolute; /* Absolute positioning */
  bottom: 100%; /* Aligns the bottom of the modal with the top of the menuBar */
  min-width: 190px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  background: var(--color-7);
  opacity: 0.97;
  display: flex;
  flex-direction: column; /* For better control over modal content layout */
  justify-content: center;
  padding: 20px 0; /* Add some padding at the top and bottom */
  border-radius: 8px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.modalContent {
  background: var(--color-7);
  margin: 0 auto; /* Center the content */
  padding: 20px;
  border-radius: 16px; /* This gives rounded corners to the content inside the modal */
  max-width: 90%; /* Prevent the modal content from being too wide */
}


#userInfo {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 768px) { /* Assuming 768px is your breakpoint for medium devices */
  #userInfo {
      /* Styles for smaller screens */
      display: flex;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      width: 100%;
      color: rgb(255, 255, 255);
  }
}



@media (max-width: 768px) {
  #userInfo .address {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .user-address {
      display: none;
  }
}


.balance-wr {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  margin-left: 10px;
  padding: 6px 16px;

}

@keyframes status {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

.user-address-label {
  font-weight: bold;
  
}

.user-address-value {
  padding: 6px 16px;
  color: var(--color-6)

}

 a:visited { 
  text-decoration: none; 
  color: var(--color-5); 
 }

@media (max-width: 768px) {
  #userInfo .user-address-value, .user-address-label {
    display: none;
  }
}

.balances-label {
  font-weight: bold;
}

.balances-value {
  padding: 6px 16px;

}

.logoeth {
  margin-top: 10px;
}


.status:after {
  animation: status 2s ease-in-out infinite forwards;
  background: #ec3f3f;
  border-radius: 50%;
  content: "";
  opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.status-connected:after {
  animation: status 2s ease-in-out infinite forwards;
  background: #00ff00;
  border-radius: 50%;
  content: "";
  opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}





